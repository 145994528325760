import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // initFlowbite()
    
    if ( this.isMatch() ) {
      this.hideUncommonValues(
        this.getColorSelectors(),
        this.commonColorValues()
      )
    }
    super.connect()
  }
    
  static targets = [  
    'components'
  ]
  

  // Getters and Setters  
  getConfigurator() {
    this.submitConfigurator();
  }

  getColorSelectors(offset = 0) {
    let selectors = Array.from(document.querySelectorAll('[id $= "_COLOR"]'));

    return selectors.slice(offset, selectors.lentgh)
  }

  setConfiguratorAction() {
    let form = this.element.closest('form')
    form.action = '/brands/1/configurator';
    document.getElementById('generate').disabled = true;
  }
  

  // Needed to set names of selection for backend selection logic
  setSelected(component) {
    let values = this.getSelectedOptions(component);
    values.forEach( value => {
      this.addSelectedAttribute(value, component.id)
    })

  }

  fullfillSelection() {
    this.componentsTargets.forEach(component => {
      this.setSelected(component);
    });
  }


  // Main Functions
  submitConfigurator() {
    this.loading();
    this.fullfillSelection();   
    this.element.requestSubmit();
  }

  submitGeneration() {
    let form = this.element.closest('form')
    form.action = '/brands/1/generate' // static action until we get more brands for configuration
    this.loading();
    form.requestSubmit();
  }

  addSelectedAttribute(value, element) {
    let hiddenTag = document.createElement('input');
    hiddenTag.hidden = true;
    hiddenTag.name = "selected[" + element + "][]";
    hiddenTag.value = value;
    this.element.append(hiddenTag);
  }

  updateSelected(event) {
    if (this.colorSelectorsEmpty()) {
      return;
    }

    if ( this.isMatch() ) {
      if (!this.hasCommonColorValues()) {
        this.uncheckMatchColors();
        alert('No common values for components colors')
      } else {
        this.hideUncommonValues(
          this.getColorSelectors(),
          this.commonColorValues()
        )
        this.matchSelection(event.srcElement);
      }
    } else {
      this.showAllValues(
        this.getColorSelectors(),
      )
    }
  }

  disableSelectors(offset = 1) {
    let selectors = this.getColorSelectors(offset)

    selectors.forEach( selector => {
      selector.disabled = true;
    })
  }

  enableSelectors(offset = 1) {
    let selectors = this.getColorSelectors(offset)

    selectors.forEach( selector => {
      selector.disabled = false;
    })
  }
   
  matchSelection(clickedSelector) {
    let selection = [];
    let colorSelectorsTargets = this.getColorSelectors();
    colorSelectorsTargets = Array.from(colorSelectorsTargets).filter(function(item) {
      return item !== clickedSelector
    })
    selection = [...this.getSelectionFrom(clickedSelector)]
    
    this.setMatchedOptions(colorSelectorsTargets, selection);
    
  }

  // Helpers
  loading() {
    document.getElementById('loader').classList.remove('hidden');
  }

  showAllValues(selectors){
    selectors.forEach( selector => {
      Array.from(selector.options).forEach ( option => {
        option.style.display = 'block';
      })
    })
  }

  hideUncommonValues(selectors, valuesToKeep) {
    selectors.forEach( selector => {
      this.hideOptions(selector, valuesToKeep);
    })
  }

  hideOptions(selectElement, valuesToKeep) {
    const options = selectElement.options;
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if ( !this.findIntersection( valuesToKeep, option.dataset.colorValue.split(',') ) ) {
        option.style.display = 'none';
        option.selected = false;
      } else {
        option.style.display = '';
      }
    }
  }

  setMatchedOptions(selectors, values) {
    selectors.forEach (selector=>{
      let selectorOptions = Array.from(selector.options)
      selectorOptions.forEach(option=>{
        if (this.findIntersection(values, option.dataset.colorValue.split(','))) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      })
    })
  }

  isMatch() {
    return document.getElementById('match_color').checked;
  }

  uncheckMatchColors() {
    document.getElementById('match_color').checked = false;
  }

  colorSelectorsEmpty() {
    let colorSelector = this.getColorSelectors()

    colorSelector.forEach(selector => {
      if (selector.options.length <= 1) {
        return true;
      }
    })

    return false;
  }

  hasCommonColorValues() {
    return this.commonColorValues().length > 1;
  }

  commonColorValues() {
    let commonValues = [];
    let colorSelectorsTargets = this.getColorSelectors();
    let options = Array.from(colorSelectorsTargets).map( colorSelector => { 
      return this.getOptionsFrom(colorSelector)
    })

    commonValues = this.getCommonValues(options.reverse());

    return commonValues;
  }


  getCommonValues(arrays) {
    // Define an empty intersection array
    let intersection = [];
    // If there are no arrays, return the empty intersection array
    if (arrays.length === 0) {
      return intersection;
    }
    // Iterate through each value in the first array
    for (let i = 0; i < arrays[0].length; i++) {
      let currentValue = arrays[0][i];
      let foundInAll = true;
      // Check if the value is in all other arrays
      for (let j = 1; j < arrays.length; j++) {
        if (!this.findIntersection(arrays[j], currentValue)) {
          foundInAll = false;
          break;
        }
      }
      // If the value is in all arrays, add it to the intersection array
      if (foundInAll && !intersection.includes(currentValue)) {
        intersection.push(currentValue.join('/'));

        currentValue.forEach(value => {
          intersection.push(value);
        });
      }
    }

    // Return the intersection array
    return intersection;
  }

  findIntersection(arrOfArrays, arrOfString) {
    // Flatten the array of arrays into a single array
    const flattenedArray = arrOfArrays.flat();
    // Convert the array of strings into a Set for efficient membership checking
    const stringSet = new Set(arrOfString);

    // Iterate through the flattened array and check if any element is present in the stringSet
    for (let i = 0; i < flattenedArray.length; i++) {
      if (stringSet.has(flattenedArray[i])) {
        return true; // Intersection found
      }
    }

    return false; // No intersection found
  }

  getSelectionFrom(element) {
    // return Array.from(element.querySelectorAll("option:checked"),e=>e.value);
    return Array.from(Array.from(element.querySelectorAll("option:checked")),e=>e.dataset.colorValue.split(','));
  }

  getOptionsFrom(element) {
    let options = Array.from(element.querySelectorAll("option"))

    return Array.from(options,e=>e.dataset.colorValue.split(','));
  }

  getSelectedOptions(element) {
    let selectedOptions = 
    Array.from(element.selectedOptions)
    .map( opt => { 
      return opt.text
    });
    
    return selectedOptions;
  }
  
}
