import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flowbite"
export default class extends Controller {
  static targets = ['updateModal', 'modelImage']
  
  connect() {}

  update(event) {
    const modelId = event.target.dataset.modelId;
    const modelName= event.target.dataset.modelName;
    const modelQty= event.target.dataset.modelQty;
    
    this.updateModalTarget.getElementsByTagName('h3')[0].innerHTML = modelName + ' - Update Inventory';
    this.updateModalTarget.querySelector('#updateForm').action = '/models/' + modelId + '/update';
    this.updateModalTarget.querySelector('#model_qty').value = modelQty;
  }

  showImage(event) {
    const data = event.target.dataset;
    
    this.modelImageTarget.getElementsByTagName('img')[0].src = data.imgUrl;
  }

}
