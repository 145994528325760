import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="flowbite"
export default class extends Controller {
  static targets = ['modelImage']
  
  connect() {}

  showImage(event) {
    const data = event.target.dataset;
    
    this.modelImageTarget.getElementsByTagName('img')[0].src = data.imgUrl;
  }
}
